/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import MediaPatientAPI, { PatchParams, Referral } from "../../services/MediaPatient";
import { User } from "../../services/UserService";
import moment from "moment";
import DisplayHl7, { PatientDetails } from "./hl7Files/DisplayHl7";
import { ReferralStatus, useGetReferralStatusesQuery } from "../../services/ReferralStatusService";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { iconForStatus, originColor, originName } from "./table/Columns";
import store from "../../redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import PatientInformation from "../patients/PatientInformation";
import PrintReferralImage from "./hl7Files/print/PrintReferralImage";
import { CurrentUser } from "../../services/User";

export interface ReferralInfoParams {
  referral: Referral;
  users: User[] | null;
  refreshReferrals: () => void;
}

export const ReferralInfo: React.FC<ReferralInfoParams> = (props: ReferralInfoParams) => {
  const [referral, setReferral] = React.useState<Referral>(props.referral);
  const [referralStatusOpen, setReferralStatusOpen] = React.useState<boolean>(false);
  const { getIdTokenClaims } = useAuth0();
  const currentUser = store.getState().currentUser as CurrentUser;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hl7PatientDetails, setHl7PatientDetails] = React.useState<PatientDetails | null>(null);

  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [saveButtonText, setSaveButtonText] = React.useState<string>("Save");

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const onLoad = async () => {
      const accessToken = await getAccessToken();
      if (accessToken) {
        setToken(accessToken);
      }
    };
    onLoad();
  }, []);

  const { data: referralStatusData, isLoading: referralStatusLoading } =
    useGetReferralStatusesQuery(
      {
        tenantSlug: currentUser?.tenantSlug,
        accessToken: token || "",
      },
      {
        skip: !currentUser?.tenantSlug || !token, 
      },
    );

  const referralStatuses = referralStatusData?.data || [];


  const updateReferral = async (params: PatchParams) => {
    setIsSaving(true);
    const currentUser = store.getState().currentUser;
    const referralId = referral.id;
    

    const response = await MediaPatientAPI.update(currentUser, token, referralId, params);

    if (response.error) {
      setReferralStatusOpen(false);
      setIsSaving(false);
      setTimeout(() => {
        setSaveButtonText("Save unsuccessful");
      }, 2500);
      alert(response.error);
    } else {
      setIsSaving(false);
      setReferralStatusOpen(false);
      setSaveButtonText("Saved successfully");

      const referral = response.data as Referral;
      setReferral(referral);
      props.refreshReferrals();

      setTimeout(() => {
        setSaveButtonText("Save");
      }, 2500);
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  const isHl7 = (url: string) => {
    return url.includes(".hl7") || url.includes(".txt") || url.includes(".HL7");
  };


  return (
    <div className="rounded-lg" style={{overflow: "auto", maxHeight: "100vh"}}>
      <section  className="grid grid-cols-1 sm:grid-cols-12">
        <div className="sm:col-span-9">
          <div className="flex justify-between p-4">
            <h2 className="text-xl font-semibold">Referral</h2>

            {!referralStatusLoading && <div>
              <div className="inline-block rounded-full my-1 my-1 py-1 px-4 cursor-pointer" 
                onClick={() => {
                  setReferralStatusOpen(!referralStatusOpen);
                }}
                style={{ backgroundColor: referralStatuses?.find((rs: ReferralStatus) => rs.name === referral.status)?.metadata?.color || "gray"}}
              >
                <div className="flex items-center py-1 px-2 cursor-pointer">
                  <img 
                    className="object-fill h-5 w-5 pr-1" 
                    src={iconForStatus(referralStatuses?.find((rs: ReferralStatus) => rs.name === referral.status)?.metadata?.iconName)} 
                    alt="Referral" 
                    style={{ fill: "white" }}
                  />
                  <div className="font-medium text-white text-xs mx-2 capitalize">{referral.status}</div>
                </div>
              </div>
              {
                referralStatusOpen && (
                <div className="absolute flex flex-col bg-white rounded-lg border p-2 mt-2">
                  {referralStatuses?.map((rs: ReferralStatus) => {
                    return <div 
                      key={rs.id}
                      className="inline-block rounded-full my-1 my-1 py-1 px-4 cursor-pointer" 
                      style={{ backgroundColor: rs.metadata?.color}}
                      onClick={() => {
                        updateReferral({
                          referralStatusId: rs.id,
                          userId: null,
                          firstName: null,
                          lastName: null,
                          address: null,
                          city: null,
                          state: null,
                          zipCode: null,
                          medicareNumber: null,
                          dateOfBirth: null,
                          email: null
                        });
                      }}
                    >
                      <div className="flex items-center py-1 px-2 cursor-pointer">
                        <img className="object-fill h-5 w-5 pr-1" src={iconForStatus(rs.metadata?.iconName)} alt="Referral"/>
                        <div className="font-medium text-white text-xs mx-2 capitalize" style={{color: "white"}}>{rs.name}</div>
                      </div>
                    </div>;
                  })}
                </div>
                )
              }
            </div>}
          </div>
          <div className="grid gap-3 px-4 pb-4 md:grid-cols-2">
            <div>
              <label className="block mb-2 text-sm font-medium">Assigned to</label>
              <select
                id="first_name"
                className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
                value={referral.user?.id}
                onChange={(e) => updateReferral({
                  referralStatusId: null,
                  userId: e.target.value,
                  firstName: null,
                  lastName: null,
                  address: null,
                  city: null,
                  state: null,
                  zipCode: null,
                  medicareNumber: null,
                  dateOfBirth: null,
                  email: null
                })}
              >
                <option value="">Select a user</option>
                {
                  props.users?.map((user: User) => {
                    return <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>;
                  })
                }
              </select>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Origin</label>
              <div className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-1">
                <div
                  className={`font-medium ${originColor(props.referral)} inline-block rounded-full px-2 text-white`}
                  
                >
                  <div className="text-xs flex items-center block capitalize" style={{ height: "30px" }}>
                    {originName(props.referral)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Preferred Date</label>
              <input type="text" id="preferred_date" className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5" 
                value={referral.bookingPreference?.date ? moment.utc(referral.bookingPreference.date).format("DD/MM/YYYY") : ""} 
                disabled
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Preferred Time</label>
              <input type="text" id="preferred_date" className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5" 
                value={referral.bookingPreference?.time || ""} 
                disabled
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium">Created at</label>
              <input type="text" id="first_name" className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5" 
                value={moment.utc(referral.createdAt).local().format("h:mm:ss A - DD/MM/YYYY") } 
                disabled
              />
            </div>
          </div>

          {
            isHl7(referral.url) && <DisplayHl7 hl7S3Url={referral.url} setHl7PatientDetails={setHl7PatientDetails}/>
          }

          {
            !isHl7(referral.url) && <PrintReferralImage imageS3Url={referral.url} />
          }
        </div>

        <div className="sm:col-span-3 border m-3 border-gray-200 bg-gray-50 rounded-lg">
          <PatientInformation 
            referral={props.referral}
            updateReferral={updateReferral}
            isSaving={isSaving}
            saveButtonText={saveButtonText}
          />
        </div>
      </section>
    </div>
  );
};

export default ReferralInfo;
