import React, { useState } from "react";
import { Organisation } from "../ReferralUploadWrapper";
import { Location } from "../../../models/Location";
import { useGetLocationsQuery } from "../../../services/LocationService";
import ClientHeader from "./ClientHeader";
import TopBar from "./TopBar";
import LocationMap from "../../map/LocationMap";

export interface Params {
  organisation: Organisation;
  setCurrentLocation: (location: Location | null) => void;
}

export interface OpenCageLocation {
  name: string;
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  locationId?: string;
}

const SelectLocation: React.FC<Params> = (props: Params) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openCageLocations, setOpenCageLocations] = useState<
    OpenCageLocation[] | []
  >([]);
  const [selectedSearchResult, setSelectedSearchResult] =
    useState<OpenCageLocation | null>(null);
  const { data: locationsData, isLoading: locationsIsLoading } =
    useGetLocationsQuery({
      slug: props.organisation.slug,
      lat: selectedSearchResult?.coordinates.lat.toString(),
      lng: selectedSearchResult?.coordinates.lng.toString(),
      radiusInMeters: 10000,
    });
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  ); // selected Clinic from results
  const [loadingOpenCageResults, setLoadingOpenCageResults] = useState(false);

  const submitSearch = async (query: string) => {
    if (query === "") {
      return;
    }

    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(query)}&key=${process.env.REACT_APP_OPEN_CAGE_API_KEY}`;

    try {
      setLoadingOpenCageResults(true);

      const response = await fetch(url);

      setLoadingOpenCageResults(false);

      if (!response.ok) {
        throw new Error("Failed to fetch data from OpenCage API");
      }

      const data = await response.json();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const locations = data.results.map((result: any) => ({
        name: result.address,
        address: result.formatted,
        coordinates: {
          lat: result.geometry.lat,
          lng: result.geometry.lng,
        },
      }));

      setOpenCageLocations(locations);

      return locations;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleSelectingSearchResult = (location: OpenCageLocation) => {
    setSelectedSearchResult(location);
    setOpenCageLocations([]);
  };

  const handleLocationClick = (location: Location) => {
    setSelectedLocation(location);
  };

  const handleMarkerClick = (location: OpenCageLocation) => {
    const locations = locationsData?.data?.locations;
    const loc = locations?.find((loc) => loc.id === location.locationId);

    if (loc) {
      props.setCurrentLocation(loc);
    } else {
      console.warn("No matching location found for ID:", location.locationId);
    }
  };

  return (
    <div className="content-center">
      <div className="mx-auto max-w-[600px]">
        {
          <div>
            <TopBar heading={"Select Location"} />
            <ClientHeader
              organisation={props.organisation}
              currentLocation={null}
            />
          </div>
        }

        {!locationsIsLoading && (
          <div className="px-4">
            <div className="flex mb-2 w-full">
              <input
                type="text"
                placeholder="Search by suburb, postcode or address"
                className="flex-grow p-2 border border-r-0 rounded-l-md dark:border-gray-700"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <button
                className="p-2 bg-blue-500 text-white rounded-r-md border border-l-0 dark:border-gray-700"
                onClick={() => {
                  setSelectedSearchResult(null);
                  setSelectedLocation(null);
                  submitSearch(searchQuery);
                }}
              >
                Search
              </button>
            </div>

            {loadingOpenCageResults && (
              <div className="flex justify-center pt-8">
                <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
              </div>
            )}

            {openCageLocations.length > 0 &&
              !loadingOpenCageResults && ( // show open cage search results
                <div>
                  <p className="pb-2 font-bold">Choose a location to find the nearest clinic:</p>
                  {openCageLocations?.map((location) => {
                    return (
                      <div
                        className="cursor-pointer px-2 py-3 border border-gray-200 hover:border-2 rounded dark:border-gray-700 dark:hover:border-gray-700 mb-3 transition-all"
                        key={location.address}
                        onClick={() => {
                          handleSelectingSearchResult(location);
                        }}
                      >
                        <p className="w-full ms-2 font-bold">
                          {location.address}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}

            {!selectedSearchResult &&
              locationsData?.data.locations &&
              openCageLocations.length === 0 &&
              !loadingOpenCageResults && ( // On mount, show map with all locations upload loading page
                <LocationMap
                  centerLocation={
                    selectedLocation
                      ? locationToMapLocation(selectedLocation)
                      : null
                  }
                  locations={locationsData?.data.locations.map(
                    locationToMapLocation,
                  )}
                  zoom={selectedLocation === null ? null : 16}
                  onMarkerClick={(location) => handleMarkerClick(location)}
                />
              )}

            {selectedSearchResult &&
              locationsData?.data.locations &&
              openCageLocations.length === 0 &&
              !loadingOpenCageResults && ( // show map after selecting openCageLocations
                <LocationMap
                  centerLocation={
                    selectedLocation !== null
                      ? locationToMapLocation(selectedLocation)
                      : selectedSearchResult
                  }
                  locations={
                    locationsData?.data.locations?.map(locationToMapLocation) ||
                    []
                  }
                  zoom={selectedLocation === null ? null : 16}
                  onMarkerClick={(location) => handleMarkerClick(location)}
                />
              )}

            {locationsData?.data.locations &&
              openCageLocations.length === 0 &&
              !loadingOpenCageResults && (
                <div className="pt-2">
                  {!selectedSearchResult && (
                    <p className="font-bold text-xl">All our clinics</p>
                  )}

                  {selectedSearchResult && (
                    <p className="font-bold text-xl pt-4">Select a clinic</p>
                  )}

                  <p className="text-sm pb-2">
                    Please choose a clinic for your procedure:
                  </p>
                  {locationsData?.data.locations?.map((location) => {
                    return (
                      <div
                        className={`${selectedLocation !== null && selectedLocation === location ? "border-2" : ""} cursor-pointer px-2 py-3 hover:bg-gray-100 border border-gray-200 rounded dark:border-gray-700 dark:hover:border-gray-700 mb-3 transition-all`}
                        key={location.id}
                        onClick={() => handleLocationClick(location)}
                      >
                        <div className="flex justify-between">
                          <div>
                            <p className="w-full ms-2 font-bold">
                              {location.name}
                            </p>
                            <p className="w-full ms-2 text-sm">
                              {location.streetAddress},
                            </p>
                            <p className="w-full ms-2 text-sm">{`${location.suburb}, ${location.state}, ${location.postCode}`}</p>
                          </div>
                          {selectedLocation === location && (
                            <div className="flex items-center">
                              <button
                                className="bg-blue-500 p-2 rounded-lg text-white"
                                onClick={() =>
                                  props.setCurrentLocation(location)
                                }
                              >
                                Select Clinic
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectLocation;

export const locationToMapLocation = (location: Location) => {
  return {
    name: location.name,
    address: location.streetAddress,
    locationId: location.id,
    coordinates: {
      lat:
        (location.coordinates?.lat &&
          parseFloat(location.coordinates?.lat)) ||
        0, // Convert lat to number
      lng:
        (location.coordinates?.lng &&
          parseFloat(location.coordinates?.lng)) ||
        0, // Convert lng to number
    },
  };
};